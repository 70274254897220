<template>
	<div>
		<base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center">
			<!-- Mask -->
			<!-- <span class="mask bg-gradient-success opacity-8"></span> -->
			<!-- Header container -->
			<div class="container-fluid d-flex align-items-center">
				<div class="row">
					<div class="col-sm-12">
						<h1 class="display-2 text-white">
							{{
								newsIndex == undefined
									? "Add news"
									: "Edit news"
							}}
						</h1>
						<p class="text-white mt-0 mb-0">Publish new news!</p>
					</div>
				</div>
			</div>
		</base-header>

		<div class="container-fluid mt--4">
			<div class="row">
				<div class="col-9">
					<base-input
						placeholder="News Title"
						class="input-group-alternative"
						alternative=""
						v-model="newsData.title"
					>
					</base-input>
				</div>
				<div class="col-3">
					<select
					placeholder="News Type"
						v-model="newsData.news_type"
						name="newsType"
						id="newsType"
						class="form-control"
					>
						<option :value="null">News Type</option>
						<option value="0">Schemes</option>
						<option value="1">Events</option>
						<option value="2">General</option>
					</select>
				</div>
			</div>
			<div  class="row">
				<div class="col-12 mb-4">
					<input
						placeholder="image"
						@change="onFileChange"
						type="file"
						class="form-control"
						alternative=""
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<ckeditor
						@ready="onEditorReady"
						:editor="editor"
						v-model="newsData.news_data"
					></ckeditor>
				</div>
			</div>
			<div class="row mt-4">
				<!-- <div class="col">
					<label for="">Income</label>
					<select
						v-model="newsData.income"
						name="income"
						placeholder="Income"
						id="income"
						class="form-control"
					>
						<option value="">All</option>
						<option value="3">Above 1 Lakh Annually</option>
						<option value="2">
							Between 1 Lakh and 50000 Annually
						</option>
						<option value="1">Below 50000 Annually</option>
					</select>
				</div> -->
				<div class="col">
					<label for="state" class="">State</label>
					<select
						v-model="newsData.state"
						placeholder="State"
						name="state"
						id="state"
						class="form-control"
						@change="fetchRegion()"
					>
						<option value="">All</option>
						<option
							v-for="state in $store.state.regionsData.stateList"
							:key="state.id"
							:value="state.id"
						>
							{{ state.name }}
						</option>
					</select>
				</div>
				<div class="col">
					<label for="region" class="">Region</label>
					<select
						v-model="newsData.region"
						placeholder="Region"
						name="region"
						id="region"
						class="form-control"
						@change="fetchSubregion()"
					>
						<option value="">All</option>
						<option
							v-for="region in $store.state.regionsData.regions"
							:key="region.id"
							:value="region.id"
						>
							{{ region.name }}
						</option>
					</select>
				</div>
				<div class="col">
					<label for="sub_region">Sub region</label>
					<select
						v-model="newsData.sub_region"
						name="sub_region"
						placeholder="Sub region"
						id="sub_region"
						class="form-control"
					>
						<option value="">All</option>
						<option
							v-for="sub_region in $store.state.regionsData
								.sub_regions"
							:key="sub_region.id"
							:value="sub_region.id"
						>
							{{ sub_region.name }}
						</option>
					</select>
				</div>
				<div v-if="newsData.news_type==1" class="col">
					<label for="">Event Date</label>
					<input
						v-model="newsData.event_date"
						name="event_date"
						placeholder="Event Date"
						id="event_date"
						class="form-control"
						type="date"
					/>
				</div>
			</div>
			<div class="row mt-4">
				<div class="col text-right">
					<button class="btn btn-danger" @click.prevent="goBack()">
						Cancel
					</button>
				</div>
				<div class="col">
					<button
						class="btn btn-success"
						@click.prevent="publishNews()"
					>
						{{ buttonText }}
					</button>
				</div>
			</div>
		</div>

		<loader :displayLoader="displayLoader"></loader>
	</div>
</template>
<script>
import ClassicEditor from "@/plugins/ckeditor.js";

export default {
	name: "add-news",
	props: ["newsIndex"],
	data() {
		return {
			displayLoader: false,
			newsTitle: "",
			editor: ClassicEditor,
			editorData: "",
			buttonText: "Publish",
			newsData: {
				title: "",
				malayalam_title: "",
				news_data: "",
				malayalam_news_data: "",
				news_status: "created",
				news_type: null,
				news_image: null,
				income: "",
				region: "",
				sub_region: "",
				state: "",
				event_date: "",
			},
			files: [],
		};
	},
	methods: {
		publishNews() {
			var title = this.newsData.title;
			var content = this.newsData.news_data;
			var date=this.newsData.event_date;
			var error = undefined;
			if (title.trim() == "") {
				error = "Title cannot be blank!";
			} else if (content.trim() == "") {
				error = "Content cannot be blank!";
			}else if(this.newsData.news_type===1 && date ===''){
				error="Event Date must be selected"
			}

			if (error) {
				this.$notify({
					title: error,
					icon: "fa fa-exclamation-triangle",
					type: "danger",
				});
				return;
			}

			this.newsData.title = title;
			this.newsData.news_data = content.replaceAll(
				"<img src",
				'<img class="img-fluid" src'
			);
			this.displayLoader = true;
			
			var newsIndex = this.newsIndex; // If undefined, we're adding new news. Else we're editing existing news
			var formData = new FormData();
			formData.append("news_image", this.files[0]);
			formData.append("title", this.newsData.title);
			formData.append("news_data", this.newsData.news_data);
			formData.append("malayalam_title", this.newsData.malayalam_title);
			formData.append(
				"malayalam_news_data",
				this.newsData.malayalam_news_data
			);
			formData.append("news_status", this.newsData.news_status);
			formData.append("news_type", this.newsData.news_type);
			formData.append("state", this.newsData.state);
			formData.append("region", this.newsData.region);
			formData.append("sub_region", this.newsData.sub_region);
			formData.append("income", this.newsData.income);
			formData.append("event_date", this.newsData.event_date);
			if ( this.files.length == 0) {
				formData.delete("news_image");
			}
			var actionObject;
			if (newsIndex != undefined) {
				actionObject = {
					newsData: formData,
				};
			} else {
				actionObject = {
					newsData: formData,
				};
			}
			if (this.selectedNewsItem != undefined) {
				actionObject.newsData.id = this.selectedNewsItem.id;
			}

			this.$store
				.dispatch("setNewsDetails", actionObject)
				.then(() => {
					this.$notify({
						title:
							"Successfully " +
							(newsIndex == undefined ? "published" : "edited") +
							" news",
						icon: "fa fa-check",
						type: "success",
					});
					this.goBack();
				})
				.catch((error) => {
					this.$notify({
						title:
							"Failed to " +
							(newsIndex == undefined ? "publish" : "edit") +
							" news",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				})
				.then(() => {
					this.displayLoader = false;
				});

			// this.$store.dispatch('setNewsDetails',
			// 	{ title, content: content.replaceAll('<img src', '<img class="img-fluid" src'), tags, newsIndex, id: newsID }).then(() => {
			// 	this.$notify({
			// 		title: "Successfully " + ((newsIndex == undefined) ? ('published') : ('edited')) + " news",
			// 		icon: 'fa fa-check',
			// 		type: 'success',
			// 	});
			// 	this.goBack();
			// }).catch((error) => {
			// 	this.$notify({
			// 		title: "Failed to " + ((newsIndex == undefined) ? ('publish') : ('edit')) + " news",
			// 		icon: 'fa fa-exclamation-triangle',
			// 		type: 'danger',
			// 		message: error.toString(),
			// 	});
			// }).then(() => {
			// 	this.displayLoader = false;
			// });
		},
		goBack() {
			this.$router.push("/news");
		},
		onEditorReady() {
			if (this.selectedNewsItem != undefined) {
				this.newsData.title = this.selectedNewsItem.title;
				this.newsData.news_type = this.selectedNewsItem.news_type;
				// this.selectedPills = [...this.selectedNewsItem.tags];
				this.newsData.news_data = this.selectedNewsItem.news_data;
				this.newsData.state= this.selectedNewsItem.state;
				this.newsData.region = this.selectedNewsItem.region;
				this.newsData.sub_region = this.selectedNewsItem.sub_region;
				this.newsData.income = this.selectedNewsItem.income;

				this.buttonText = "Edit";
			}

			// if (this.selectedNewsItem != undefined) {
			// 	this.newsTitle = this.selectedNewsItem.tabTitle;
			// 	this.selectedPills = [...this.selectedNewsItem.tags];

			// 	this.editorData = '';
			// 	this.selectedNewsItem.tabContents.forEach((tabContent) => {
			// 		if (tabContent.title != '') {
			// 			this.editorData += '<h1>' + tabContent.title + '</h1>';
			// 		}
			// 		if (tabContent.content != '') {
			// 			this.editorData += '<p>' + tabContent.content + '</p>';
			// 		}
			// 	});

			// 	this.buttonText = 'Edit';
			// }
		},
		onFileChange(e) {
			this.files = e.target.files || e.dataTransfer.files;
			if (!this.files.length) return;
			this.newsData.news_image = this.files[0];
		},
		fetchState() {
			this.$store.dispatch("fetchState")
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch states",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
			});
		},
		fetchRegion() {
			this.$store.dispatch("fetchRegion",this.newsData.state)
			.catch((error) => {
				this.$notify({
					title: "Failed to fetch regions",
					icon: "fa fa-exclamation-triangle",
					type: "danger",
					message: error.toString(),
				});
			});
		},
		fetchSubregion(){
			if (this.newsData.region !== null)
				this.$store
					.dispatch("fetchSubRegion", this.newsData.region)
					.catch((error) => {
						this.$notify({
							title: "Failed to fetch sub regions",
							icon: "fa fa-exclamation-triangle",
							type: "danger",
							message: error.toString(),
						});
					});
		},
	},
	computed: {
		selectedNewsItem() {
			if (this.newsIndex == undefined) {
				return undefined;
			}

			return this.$store.state.newsData.newsList[this.newsIndex];
		},
	},
	watch: {
	},
	mounted() {
		this.fetchState()
		if (this.newsIndex !== undefined) {
			this.$store
				.dispatch(
					"fetchNewsDetails",
					this.$store.state.newsData.newsList[this.newsIndex].id
			)
				.then(() => {
					if (this.newsData.income == null) {
				this.newsData.income=""
			}
			if (this.newsData.region == null) {
				this.newsData.region=""
			}
			if (this.newsData.sub_region == null) {
				this.newsData.sub_region=""
			}
				})
				.catch((error) => {
					this.$notify({
						title: "Failed to fetch regions",
						icon: "fa fa-exclamation-triangle",
						type: "danger",
						message: error.toString(),
					});
				});
		}
		
	},
};
</script>
<style>
b,
strong {
	font-weight: bold !important;
}
</style>
