<template>
    <div>
        <base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center">
            <!-- Mask -->
            <!-- <span class="mask bg-gradient-success opacity-8"></span> -->
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-sm-12">
                        <h1 class="display-2 text-white">Schemes</h1>
                        <p class="text-white mt-0 mb-0">Learn what's happening around you</p>
                        <button class="btn btn-primary mt-3" @click.prevent="$router.push('/scheme/add-scheme')">Add New Scheme</button>
                    </div>
                </div>
            </div>
        </base-header>
    
        <div class="container-fluid mt--4">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="row">
                            <div class="col-5 border-right pr-0">
                                <div v-for="(scheme, index) in schemeList" :key="index" @click.prevent="selectTab(index)" class="card-header hover-darken" :class="{ 'active-tab': index == activeTab }">
                                    <div class="align-items-center">
                                        <div class="h4 mb-0" :class="[index == activeTab ? 'text' : 'text-muted']">{{ scheme.title }}
                                        <base-button class="ml-1 p-0 px-1" :type="scheme.scheme_type==0?'warning':scheme.scheme_type==1?'danger':'primary'" size="sm" >{{scheme.scheme_type==0?'Schemes':scheme.scheme_type==1?'Events':'Genaral'}}</base-button>	</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-7" v-if="activeTab != -1">
                                <div class="card-header bg-transparent">
                                    <div class="row align-items-center">
                                        <div class="col-8">
                                            <transition name="slide-fade" mode="out-in">
                                                <h5 class="h1 mb-0" :key="schemeList[activeTab].title">
                                                    {{ schemeList[activeTab].title }}
                                                </h5>
                                            </transition>
                                        </div>
                                        <div class="col-4 text-right" v-if="schemeList[activeTab].title != 'Loading...'">
                                            <button class="btn btn-primary" @click.prevent="editSchemeItem"><i class="fas fa-edit"></i></button>
                                            <button class="btn btn-danger" @click.prevent="deleteSchemeItem"><i class="fas fa-trash"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <transition name="slide-fade" mode="out-in">
                                        <p class="mb-0" :key="schemeList[activeTab].scheme_data">
                                            <span v-html="schemeList[activeTab].scheme_data"></span>
                                        </p>
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    export default {
        name: 'scheme',
        data() {
            return {
                activeTab: 0,
            }
        },
        computed: {
            schemeList() {
                return this.$store.state.schemeStore.schemeList;
            },
            schemeIndex() {
                return this.$store.state.schemeStore.schemeList.indexOf(this.schemeList[this.activeTab]);
            }
        },
        methods: {
            selectTab(index) {
                this.activeTab = index;
            },
            editSchemeItem() {
                var schemeIndex = this.schemeIndex;
                this.$router.push({name: 'add scheme', params: { schemeIndex }});
            },
            deleteSchemeItem() {
                this.displayLoader = true;
                this.$store.dispatch('deleteScheme', { index: this.schemeIndex, id: this.schemeList[this.activeTab].id }).then(() => {
                    this.displayLoader = false;
                    this.$notify({
                        title: "Successfully deleted scheme",
                        icon: 'fa fa-check',
                        type: 'success',
                    });
                }).catch((error) => {
                    this.displayLoader = false;
                    this.$notify({
                        title: "Failed to delete scheme",
                        icon: 'fa fa-exclamation-triangle',
                        type: 'danger',
                        message: error.toString(),
                    });
                });
            },
            fetchScheme() {
                this.$store.dispatch('fetchSchemeList').catch((error) => {
                    this.$notify({
                        title: "Failed to fetch scheme",
                        icon: 'fa fa-exclamation-triangle',
                        type: 'danger',
                        message: error.toString(),
                    });
                });
            },
        },
        mounted() {
            this.fetchScheme();
        }
    }
    </script>
    <style scoped>
    /* Maybe use transitions from vue2-transitions instead of the manual way? */
    
    /* Enter and leave animations can use different */
    /* durations and timing functions.              */
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    
    .slide-fade-leave-active {
        transition: all .4s ease;
    }
    
    .slide-fade-enter,
    .slide-fade-leave-to
    
    /* .slide-fade-leave-active below version 2.1.8 */
        {
        transform: translateX(50px);
        opacity: 0;
    }
    </style>
    