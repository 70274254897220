<template>
<div>

	<base-header class="header pb-6 pt-7 pt-lg-8 d-flex align-items-center">
		<!-- Mask -->
		<!-- <span class="mask bg-gradient-success opacity-8"></span> -->
		<!-- Header container -->
		<div class="container-fluid d-flex align-items-center">
			<div class="row">
				<div class="col-sm-12">
					<h1 class="display-2 text-white">Contact Us</h1>
					<p class="text-white mt-0 mb-0">Find us here!</p>
				</div>
			</div>
		</div>
	</base-header>

	<div class="container-fluid mt--4">
		<div class="row">
			<div class="col-sm-6">
				<h1 class="mt-6 mb-3">Kerala Agro Business Company Limited (KABCO)</h1>
				<div class="p-2">
					<span class="fa fa-map-marker pr-2"></span>
					Third Floor - Trans Towers,<br>
					Vazhuthacaud,<br>
					Thiruvananthapuram – 695014
				</div>
				<div class="p-2">
					<span class="fa fa-phone pr-2"></span>
					<a href="tel:+91 471 460 1888">+91 471 460 1888</a>
				</div>
				<div class="p-2">
					<span class="fa fa-envelope pr-2"></span>
					<a href="mailto:kabco.info@gmail.com" target="_blank">kabco.info@gmail.com</a>
				</div>
				<div class="p-2">
					<span class="fa fa-globe pr-2"></span>
					<a href="https://kabco-six.vercel.app/index.html" target="_blank">www.kabco.in</a>
				</div>
				<div class="p-2">
					<span class="fab fa-linkedin pr-2"></span>
					<a href="#" target="_blank">Linkedin</a>
				</div>
				<div class="p-2">
					<span class="fab fa-facebook pr-2"></span>
					<a href="#" target="_blank">Facebook</a>
				</div>
			</div>
			<div class="col-sm-6">
				<div class="card shadow border-0">
					<iframe width="100%" height="500px" class="rounded" src="https://maps.google.com/maps?q=mizone%20thirunanthapuram&t=&z=17&ie=UTF8&iwloc=&output=embed" scrolling="no" />
				</div>
			</div>
		</div>
	</div>

</div>
</template>
<script>
export default {}
</script>
<style>
</style>
